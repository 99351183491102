import { h } from "@stencil/core";
const TEMPLATE_INDICATOR_DEFAULT_SIZE = 16;
export const TemplateIndicator = (props) => {
    var _a;
    const size = `${TEMPLATE_INDICATOR_DEFAULT_SIZE}px`;
    const style = {
        display: "flex",
        width: size,
        height: size,
        "background-color": "white",
        "border-radius": "none",
    };
    const actualType = (_a = props.type) !== null && _a !== void 0 ? _a : "template";
    if (actualType === "template") {
        style["border-radius"] = size;
    }
    return (h("div", { style: style }, h("dx-state-icon", { state: actualType })));
};
export const FloatingTemplateIndicator = (props) => {
    var _a, _b;
    return (h("div", { class: "template-indicator", style: {
            position: "absolute",
            left: (_a = props.left) !== null && _a !== void 0 ? _a : FloatingTemplateIndicatorDefaultConfiguration.left,
            top: (_b = props.top) !== null && _b !== void 0 ? _b : FloatingTemplateIndicatorDefaultConfiguration.top,
            "z-index": "1",
            display: props.visible ? "unset" : "none",
        } }, h(TemplateIndicator, { type: props.type })));
};
export const FloatingTemplateIndicatorDefaultConfiguration = {
    top: "",
    left: "-8px",
    visible: false,
};
export function computeTemplateIndicatorVisible(config) {
    return config.showIndicator && !config.readonly && !config.disabled;
}
